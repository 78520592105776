import React, { useEffect } from 'react'
import Img from 'gatsby-image'
import anime from 'animejs'

//import PropTypes from 'prop-types'
import './style.scss'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { Parallax, withController } from 'react-scroll-parallax'

import Video from '../Video/HTML'

function Hero(props) {
	const slides = props.slides
	const type = props.size

	// Take care of parallax and image alternative modes for responsive
	useEffect(() => {
		// RAF to update parallax position, it gets lost sometimes otherwise, especially on page changes
		window.requestAnimationFrame(() => {
			props.parallaxController.update()
		})
	},[props.parallaxController])

	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		fade: true,
		speed: 800,
		pauseOnFocus: true,
		autoplay: true,
		autoplaySpeed: 8000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		className: 'c5'
	}

	useEffect(() => {
		anime({
			targets: '.element',
			translateY: ['100%',0],
			duration: 400,
			delay: 300,
			easing: 'easeInOutSine'
		})
	},[])

	const SlidesPack = slides.map((slide, i) => {

		const image = slide.img?.localFile?.childImageSharp.fluid
		const content = slide.wysiwyg
		const video = slide.video?.localFile?.url

		if(type === 'size-xs') {
			return (
				<div key={`slide-${i}`} className="slide grid-12 x1">
					<div className="span-6 relative">
						{ image ?
							<Img fluid={image}
								imgStyle={{objectFit: 'cover'}}
								objectPosition='50% 50%'
								loading='eager'
								fadeIn={true}
							/>
						: null }
						
						{ video ?
							<>
								<Video file={video} />
							</>
						: null }
					</div>
					
					<div className='hero-content span-6 border-left padd-3'>
						<div className="hero-content-animator">
							<div className='hero-content-box splittext-chars' dangerouslySetInnerHTML={{__html: content}} />
						</div>
					</div>
				</div>
			)
		} else {
			return (
				<div key={`slide-${i}`} className="slide x1 t">
					{ image ?
						<Img fluid={image}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
							loading='eager'
							fadeIn={true}
						/>
					: null }

					{ video ?
						<>
							<Parallax className="vid-parallax" y={[-35, 35]} tagOuter="figure">
								<div className='vid-parallax-wrap'>
									<Video file={video} />
								</div>
							</Parallax>
							<div className='bg-overlay'>
								<div className='bg-overlay-x'></div>
							</div>
						</>
					: null }
					
					<div className='hero-content'>
						<div className="hero-content-animator">
							<div className='hero-content-box splittext-chars' dangerouslySetInnerHTML={{__html: content}} />
						</div>
					</div>
				</div>
			)
		}
	})

	return (
		<section id="master-hero" className={`hero border x0 t is-inview ${props.size}`}>
			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>
			<Slider {...settings}>
				{SlidesPack}
			</Slider>
		</section>
  )
}

export default withController(Hero)