import React from 'react'
import './style.scss'

import Img from 'gatsby-image'
import Typewriter from "typewriter-effect"
import Hero from "../Hero"
import CF7Basic from '../Form/CF7/Basic'

import GMap from '../Map/GMap'
import Events from './ACF/Events'
import Testimonials from './ACF/Testimonials'
import DuoContent from './ACF/DuoContent'
import HomepageModules from './ACF/HomepageModules'
import Activation from './ACF/Activation'
import OurLocations from './ACF/OurLocations'
import Faq from './ACF/FAQ'
import GallerySection from './ACF/GallerySection'

import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import ModulesWhatWeDo from './ACF/ModulesWhatWeDo'
import Sponsorships from './ACF/Sponsorships'
import ApplySection from './ACF/Apply'
import NewsModule from './ACF/NewsModule'


function Content(props) {
	//const [toggler, setToggler] = useState(false)
	const [wereReadyRef,isWereReadyInView] = useInView({ triggerOnce: true })
	
	//const item = {}

	const Sections = props.wordpressPage.acf.sections_page.map((section, i) => {
		
		if(section.__typename === 'WordPressAcf_hero') {
			const image = section.slides[0].img?.localFile?.childImageSharp.fluid
			const content = section.slides[0].wysiwyg

			return (
				<div key={`hero-main-${i}`}>
					<Hero size={section.size} slides={section.slides} image={image} content={content} />
					{/* Point of reference for past hero observer threashold, so we can calculate if the user is past hero or not */}
					<div id="header-fold-breakpoint"></div>
				</div>
			)
		}
		
		if(section.__typename === 'WordPressAcf_content') {
			const content = section.content
			const image = section.img?.localFile?.childImageSharp.fluid
			const classes = section.classes

			return (
				<section key={`section-content-${i}`} className={`content border ${classes} ${section.color} grid-12 is-inview ${section.type}`}>

					<div className="animated-border-top"></div>
					<div className="animated-border-right"></div>
					<div className="animated-border-bottom"></div>
					<div className="animated-border-left"></div>

					{ image ?
						<Img fluid={image}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
							className="animated-x"
						/>
					: null }

					<div className={`content-box ${section.color}`}>
						<div className='content-holder animated padd-4' dangerouslySetInnerHTML={{__html: content}} />
					</div>

				</section>
			)
		}
		
		if(section.__typename === 'WordPressAcf_parallax') {
			const content = section.content
			const image = section.img?.localFile?.childImageSharp.fluid

			return (
				<section key={`section-parallax-${i}`} className={`parallax c4 grid-12 is-inview ${section.type}`}>

					<div className="animated-border-top"></div>
					<div className="animated-border-right"></div>
					<div className="animated-border-left"></div>
					{ image ?
						<Img fluid={image}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
						/>
					: null }

					<div className='content-box c4 t x1'>
						<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content}} />
					</div>

				</section>
			)
		}

		if(section.__typename === 'WordPressAcf_cta') {
			const columnCount = section.column.length

			const thisSection = section.column.map((col, j) => {
				const title = col.title
				const image = col.img.localFile?.childImageSharp.fluid
				const btn_text = col.button_text
				const btn_link = col.button_link

				return (
					<div key={`cta-col-${i}-${j}`} className={`column x1 column-${j + 1}-${columnCount} is-inview`}>
						{ image ?
							<Img fluid={image}
								imgStyle={{objectFit: 'cover'}}
								objectPosition='50% 50%'
							/>
						: null }
						<div className="innards">
							<h3>{title}</h3>
							<Link to={btn_link} className="btn"><span>{btn_text}</span></Link>
						</div>
					</div>
				)
			})
			
			return (
				<section key={`section-cta-${i}`} className='cta c4 grid-12 is-inview'>
					<div className="animated-border-top"></div>
					<div className="animated-border-right"></div>
					<div className="animated-border-left"></div>

					{thisSection}

				</section>
			)
		}


		if(section.__typename === 'WordPressAcf_were_ready') {
			const image = section.img?.localFile?.childImageSharp.fluid

			return (
				<section ref={wereReadyRef} key={`section-cta-truck-${i}`} className='cta-truck border c2 grid-12 is-inview'>
					<div className="animated-border-top"></div>
					<div className="animated-border-right"></div>
					<div className="animated-border-left"></div>

					<div className="span-7 span-12-tablet center padd-4">
						{isWereReadyInView ?
						<h2>
							<Typewriter
								onInit={(typewriter) => {
									typewriter
									.changeDelay(150)
									.typeString("We're Ready.")
									.start();
								}}
									options={{
									delay: 0
								}}
							/>
						</h2>
						: null }
						<p><Link to="/lets-connect" className="btn"><span>Contact Us</span></Link></p>
					</div>

					<div className="span-5 span-12-tablet relative">
						{ image ?
							<Img fluid={image}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
							/>
						: null }
					</div>

				</section>
			)
		}


		if(section.__typename === 'WordPressAcf_logistics') {
			const image = section.img?.localFile?.childImageSharp.fluid
			console.log(section)

			return (
				<section key={`section-logistics-${i}`} className='logistics border c0 grid-12 padd-2 is-inview'>

					<div className="animated-border-top"></div>
					<div className="animated-border-right"></div>
					<div className="animated-border-left"></div>

					<div className="span-12 text-center fs-125">
						<h2 className="margin-bottom-off">Logistics, experience &amp; details all combine</h2>
						<p className="margin-top-off fat">We Walk the Walk and Talk the Talk. We have built SWARM to be a turnkey solution.</p>
					</div>

					<div className="span-3 span-12-tablet relative">
						{ image ?
							<Img fluid={image}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
							/>
						: null }
					</div>
					<div className="span-3 span-12-tablet padd-2">
						<p>Planning and producing your event is a complex set of tasks. SWARM’s experience running hundreds of events per year helps event producers and owners achieve the event’s goals.</p>
						<p>We have worked with agencies and direct clients to bring their event concepts to fruition.</p>
					</div>
					<div className="span-2 span-12-tablet padd-2 fs-85 list-wrap">
						<ul>
							<li>Budgeting</li>
							<li>Project Management</li>
							<li>Production</li>
							<li>Staffing:</li>
							<ul>
								<li>Ticketing</li>
								<li>Customer Service</li>
								<li>Laborers</li>
							</ul>
							<li>Electrical:</li>
							<ul>
								<li>Generators</li>
								<li>Distribution</li>
								<li>Lighting</li>
							</ul>
						</ul>
					</div>
					<div className="span-2 span-12-tablet padd-2 fs-85 list-wrap">
						<ul>
							<li>Equipment Rental:</li>
							<ul>
								<li>Barricades</li>
								<li>Fencing</li>
								<li>Bathrooms</li>
								<li>A/C System</li>
							</ul>
							<li>Audio Visual:</li>
							<ul>
								<li>Sound System</li>
								<li>Lighting</li>
								<li>Truss</li>
								<li>Staging</li>
							</ul>
						</ul>	
					</div>
					<div className="span-2 span-12-tablet padd-2 fs-85 list-wrap">
						<ul>
							<li>Security</li>
							<li>Janitorial</li>
							<li>Tenting and Structures</li>
							<li>Container Shops and Bars</li>
							<li>Furniture &amp; Fixtures</li>
							<li>Décor</li>
						</ul>
					</div>

				</section>
			)
		}
		
		if(section.__typename === 'WordPressAcf_contact') {

			return (
				<section key={`section-contact-${i}`} className={`contact c0 grid-12 border is-inview ${section.type}`}>

					<div className="animated-border-top"></div>
					<div className="animated-border-right"></div>
					<div className="animated-border-left"></div>

					<div className='contact-box span-5 span-12-tablet c0'>
						<CF7Basic />
					</div>

					<div className="info-box span-7 span-12-tablet c3">
						<div className="info">
							<div className="box left center">
								<p><FontAwesomeIcon icon={faMapMarkerAlt} /> SWARM OFFICE</p>
							</div>
							<div className="box center">
								<h3>2308 NW 5<span className="square">th</span> Ave Miami, FL 33127</h3>
							</div>
							<div className="box left center">
								<p><FontAwesomeIcon icon={faMapMarkerAlt} /> SWARM WAREHOUSE</p>
							</div>
							<div className="box center">
								<h3>830 NW 22<span className="square">nd</span> ST Miami, FL 33127</h3>
							</div>
							<div className="box left center">
								<h3><a href="tel:3054612700"><FontAwesomeIcon icon={faPhoneAlt} /> 305 461 2700</a></h3>
							</div>
							<div className="box center">
								<h3><a href="mailto:info@swarminc.com"><FontAwesomeIcon icon={faEnvelope} /> info@swarminc.com</a></h3>
							</div>
						</div>
						<div className="map-wrap">
							<div id="map" className="map" style={{width: '100%', height: '100%'}}>
								<GMap { ...section.map } />
							</div>
						</div>
					</div>

				</section>
			)
		}
		
		if(section.__typename === 'WordPressAcf_events') {

			return (
				<Events key={`events-${i}`} { ...section } />
			)
		}

		if(section.__typename === 'WordPressAcf_gallery') {

			return (
				<GallerySection key={`gallery-section-${i}`} { ...section } />
			)
		}
		
		if(section.__typename === 'WordPressAcf_homepage_modules') {

			return (
				<HomepageModules key={`homepage-modules-${i}`} { ...section } />
			)
		}
		
		if(section.__typename === 'WordPressAcf_news_module') {

			return (
				<NewsModule key={`news-module-${i}`} { ...section } />
			)
		}
		
		if(section.__typename === 'WordPressAcf_duo_content') {

			return (
				<DuoContent key={`duo-content-${i}`} { ...section } />
			)
		}
		
		if(section.__typename === 'WordPressAcf_apply') {

			return (
				<ApplySection key={`apply-section-${i}`} { ...section } />
			)
		}
		
		if(section.__typename === 'WordPressAcf_sponsorships') {

			return (
				<Sponsorships key={`sponsorships-${i}`} { ...section } />
			)
		}
		
		if(section.__typename === 'WordPressAcf_what_we_do_module') {

			return (
				<ModulesWhatWeDo key={`modules-what-we-do-${i}`} { ...section } />
			)
		}
		
		if(section.__typename === 'WordPressAcf_activation') {

			return (
				<Activation key={`activation-${i}`} { ...section } />
			)
		}
		
		if(section.__typename === 'WordPressAcf_faq') {

			return (
				<Faq key={`faq-${i}`} { ...section } />
			)
		}
		
		if(section.__typename === 'WordPressAcf_our_locations') {

			return (
				<OurLocations key={`our-locations-${i}`} { ...section } { ...props } />
			)
		}
		
		if(section.__typename === 'WordPressAcf_text_block') {
			const content = section.wysiwyg

			return (
				<section key={`section-text-block-${i}`} className={`c0 grid-12 border is-inview`}>

					<div className="animated-border-top"></div>
					<div className="animated-border-right"></div>
					<div className="animated-border-left"></div>

					<div className='span-9 span-12-tablet padd-4 content-box'>
						<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content}} />
					</div>

				</section>
			)
		}
		
		if(section.__typename === 'WordPressAcf_image') {
			const image = section.img?.localFile?.childImageSharp.fluid

			return (
				<section key={`section-image-block-${i}`} className={`c5 border is-inview`}>

					<div className="animated-border-top"></div>
					<div className="animated-border-right"></div>
					<div className="animated-border-left"></div>

						{ image ?
							<Img fluid={image}
								imgStyle={{objectFit: 'cover'}}
								objectPosition='50% 50%'
							/>
						: null }

				</section>
			)
		}
		
		if(section.__typename === 'WordPressAcf_testimonials') {

			return (
				<Testimonials key={`testimonials-${i}`} { ...section }/>					
			)
		}

		return false
	})

  return (
		<>
			{Sections}
		</>
  )
}

export default Content