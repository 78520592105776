import React, { useState, useContext } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

import ModalContext from '../../../../context/ModalContext'

function Newsletter(props) {
	const modalContext = useContext(ModalContext)

	const { register, handleSubmit, errors, reset } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-name', form.name)
		data.append('your-email', form.email)
		
		const url = 'https://cms.deepsleepstudio.com/client/SWARM/wp-json/contact-form-7/v1/contact-forms/486/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					// console.log('status', response.data.status)
					// console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()

						setTimeout(() => {
							modalContext.setActive(false)
						}, 2000)
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<form
			className="grid-12 gap-1"
			name="contact"
			method="post"
			action="/thanks/"
			onSubmit={handleSubmit(onSubmit)}
		>

			{/* <label className="span-6 span-12-tablet">
				<input type="text" placeholder="Name" name="name" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.name && <p className="small margin-off">your name is required.</p>}
			</label> */}
			<label className="span-12 span-12-tablet">
				<input type="text" placeholder="E-mail" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
				{errors.email && <p className="small margin-off">a correct email is required.</p>}
			</label>

			<button className="text-center span-12" style={{textAlign: 'center'}} type="submit">Submit {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
			
			<p className="span-12 text-center message small">{message}</p>

		</form>
	)
}

export default Newsletter