import React from 'react'
import Gallery from '../../../Gallery'

//import PropTypes from 'prop-types'
import './style.scss'

function GallerySection(props) {
	return (
		<section className={`border grid-12 content-box is-inview ${props.class}`}>
			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>
			<div className="span-10 gallery-center">
				<Gallery { ...props } />
			</div>
		</section>
  )
}

export default GallerySection