import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import './style.scss'

function Activation(props) {

	const data = useStaticQuery(graphql`
	query {
		act1: file(relativePath: { eq: "Activation_1.jpg" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		},
		act2: file(relativePath: { eq: "Activation_2.jpg" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		},
		act3: file(relativePath: { eq: "Activation_3.jpg" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		},
		act4: file(relativePath: { eq: "Activation_4.jpg" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
	`)

  return (
		<section id="activation" className='activation c5 border grid-12 text-center gap-075 is-inview'>
			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>
			<div className="span-3 span-12-mobile padd-2 hover-reveal">
				<Img fluid={data.act1.childImageSharp.fluid}
					imgStyle={{objectFit: 'cover'}}
					objectPosition='50% 50%'
					loading='auto'
					fadeIn={true}
					className="bg"
				/>
				<div className="hover-reveal-front">
					<h2>Experiential Marketing &amp; Activations</h2>
				</div>
				<div className="hover-reveal-back text-left fs-85">
					<div className="text-box">
						<h3>Experiential Marketing &amp; Activations</h3>
						<p>We create experiences that challenge consumers to branch out from the ordinary and discover something new. From ideation to conceptualization and management, we develop creative solutions that deliver.</p>
						<ul>
							<li>Strategy</li>
							<li>Concept</li>
							<li>Production</li>
							<li>Activation</li>
							<li>Management</li>
							<li>Reporting</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="span-3 span-12-mobile padd-2 hover-reveal">
				<Img fluid={data.act2.childImageSharp.fluid}
					imgStyle={{objectFit: 'cover'}}
					objectPosition='50% 50%'
					loading='auto'
					fadeIn={true}
					className="bg"
				/>
				<div className="hover-reveal-front">
					<h2>Planning &amp; Production</h2>
				</div>
				<div className="hover-reveal-back text-left fs-85">
					<div className="text-box">
						<h3>Planning &amp; Production</h3>
						<p>Planning &amp; producing any event is a complex set of tasks. We work with agencies and direct clients to bring their event concepts to fruition.</p>
						<ul>
							<li>Budgeting</li>
							<li>Production &amp; Construction</li>
							<li>Staffing</li>
							<li>Equipment Rental</li>
							<li>Audio Visual</li>
							<li>Electrical</li>
							<li>Permitting &amp; City Services</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="span-3 span-12-mobile padd-2 hover-reveal">
				<Img fluid={data.act3.childImageSharp.fluid}
					imgStyle={{objectFit: 'cover'}}
					objectPosition='50% 50%'
					loading='auto'
					fadeIn={true}
					className="bg"
				/>
				<div className="hover-reveal-front">
					<h2>Marketing &amp; Advertising</h2>
				</div>
				<div className="hover-reveal-back text-left fs-85">
					<div className="text-box">
						<h3>Marketing &amp; Advertising</h3>
						<p>SWARM's core competency is bringing people together; with a marketing department on the cutting edge of technology, we bring tens of thousands of consumers to events annually.</p>
						<ul>
							<li>Content Creation</li>
							<li>PPC &amp; SEO</li>
							<li>Web Design &amp; Development</li>
							<li>Social Media Management</li>
							<li>Email Marketing</li>
							<li>Street Team Marketing</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="span-3 span-12-mobile padd-2 hover-reveal">
				<Img fluid={data.act4.childImageSharp.fluid}
					imgStyle={{objectFit: 'cover'}}
					objectPosition='50% 50%'
					loading='auto'
					fadeIn={true}
					className="bg"
				/>
				<div className="hover-reveal-front">
					<h2>Bar Operations and Food Concessions</h2>
				</div>
				<div className="hover-reveal-back text-left fs-85">
					<div className="text-box">
						<h3>Bar Operations and Food Concessions</h3>
						<p>No one does F&amp;B like SWARM - as experts in the field, our program is an industry model.</p>
						<ul>
							<li>Staffing</li>
							<li>Money Teams</li>
							<li>Catering License</li>
							<li>Liquor Liability Coverage</li>
							<li>POS System</li>
							<li>Reporting</li>
							<li>High-Volume Specialist</li>
							<li>Real-Time Monitoring</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
  )
}

export default Activation