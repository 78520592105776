import React from 'react'
import Img from 'gatsby-image'

import './style.scss'

function Location(props) {
	const image = props.acf.img.localFile?.childImageSharp.fluid

  return (
		<div className="location-details span-12 grid-12 c0">
			<div className="span-8 span-6-tablet span-12-mobile relative animated-x image-wrapper">
				<Img fluid={image}
					imgStyle={{objectFit: 'cover'}}
					objectPosition='50% 50%'
					fadeIn={true}
					loading='auto'
					className="bg"
				/>
			</div>
			<div className="span-4 span-6-tablet span-12-mobile border-left text-left padd-2" dangerouslySetInnerHTML={{__html: props.acf.details}}></div>
		</div>
  )
}

export default Location