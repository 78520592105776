import React from 'react'
import './style.scss'

import { Link } from 'gatsby'

function Event(props) {
	const eventStartDate = new Date(props.acf?.event_start_daytime.replace(/-/g, "/"))

	if(props.dummy === true) {
		return (
			<article className="event regular span-3 span-6-tablet span-12-mobile c0 is-inview">
				<div className="image c4"></div>
				<div className="description padd-1">
					<h4 className="date monofont placeholder wave">
						<span className="line"></span>
						<span className="line"></span>
						<span className="line"></span>
					</h4>
					<div className="details">
						<div className="info placeholder wave regular">
							<h5 className="line">&nbsp;</h5>
						</div>
						<div className="location placeholder wave regular">
							<p className="line"></p>
						</div>
					</div>
				</div>
			</article>
		)
	}

  return (
		<article className="event regular span-3 span-6-tablet span-12-mobile c0 is-inview">
			<Link to={`/event?id=${props.id}`} className="image c4" state={{ singleEvent: {...props}}}>
				{props.acf.img ?
					<img
						className="img-cover"
						src={props.acf.img.sizes.medium} 
						alt=""  
						srcSet={`${props.acf.img.sizes.large} 1024w,
								${props.acf.img.sizes.medium_large} 768w,
								${props.acf.img.sizes.medium} 300w,
								${props.acf.img.sizes.thumbnail} 150w`}
						sizes="(min-width: 800px) 25vw, 100vw"
					/>
				: null }
			</Link>

			<div className="description padd-1">
				{eventStartDate ?
					<h4 className="date monofont">
						<span className="weekday">{eventStartDate.toLocaleDateString('en-US', {weekday: 'short'})}</span>
						<span className="month">{eventStartDate.toLocaleDateString('en-US', {month: 'short'})}</span>
						<span className="day">{eventStartDate.toLocaleDateString('en-US', {day: '2-digit'})}</span>
					</h4>
				:
					<h4 className="placeholder wave">
						<span className="line"></span>
						<span className="line"></span>
						<span className="line"></span>
					</h4>
				}
				<div className="details">
					<div className="info">
						<h5>{props.title.rendered}</h5>
					</div>
					<div className="location">
						{props.acf.general_location}
					</div>
				</div>
			</div>
				
			{props.acf.tickets ?
				<div className="tix">
					<Link to={`/event?id=${props.id}`} className="btn flat"><span>Buy Tix</span></Link>
				</div>
			: null }
		</article>
  )
}

export default Event