import React from 'react'
import './style.scss'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function Testimonials(props) {
	const slides = props.testimonials
	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		fade: false,
		speed: 800,
		pauseOnFocus: true,
		autoplay: true,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
	}

	const SlidesPack = slides.map((slide, i) => {

		const quote = slide.quote
		const by = slide.by
		const profession = slide.profession

		return (
			<div key={`slide-${i}`} className="slide">
				<div className="wadap grid-12 gap-1-row text-center">
					<div className="span-8 span-12-tablet">
						<p className="bold"><span className="colored">"</span>{quote}<span className="colored">"</span></p>
					</div>
					<div className="span-4 span-12-tablet" style={{alignSelf: 'flex-end'}}>
						<p className="bold"><span className="colored">-</span> {by} <span className="colored">&#47;&#47;</span> {profession}</p>
					</div>
				</div>
			</div>
		)
	})

  return (
		<section className="testimonials border grid-12 c5 is-inview">
			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>
			<div className="span-12">
				<Slider {...settings}>
					{SlidesPack}
				</Slider>
			</div>
		</section>
  )
}

export default Testimonials