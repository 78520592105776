import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

import './style.scss'
import Slider from 'react-slick'

function NewsModule(props) {
	const [slidesPack, setSlidesPack] = useState()

	const data = useStaticQuery(graphql`
	query {
		allWordpressPost(limit: 5) {
			nodes {
				content
				title
				featured_media {
          localFile {
            childImageSharp {
              fluid (
                maxWidth: 1280,
                quality: 90,
                srcSetBreakpoints: [720,1280,1920,2560]
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
			}
		}
	}
	`)

	
	const slides = data?.allWordpressPost?.nodes
	useEffect(() => {

		setSlidesPack(() => {

			// Map Slides to gallery stripe
			const slidesMap = slides.map((slide, i) => {
				const image = slide.featured_media.localFile?.childImageSharp.fluid
				const title = slide.title
				const content = slide.content
				return (
					<div className="span-12 grid-12">
						<div class="animated-x gatsby-image-wrapper span-4">
							<Img fluid={image}
								imgStyle={{objectFit: 'cover'}}
								objectPosition='50% 50%'
								loading='eager'
								fadeIn={true}
							/>
						</div>
						<div class="content-box c1">
							<div class="content-holder animated padd-4">
								<h2>NEWS</h2>
								<h3>{title}</h3>
								<div className='' dangerouslySetInnerHTML={{__html: content}} />
							</div>
						</div>
					</div>
				)
			})
			return slidesMap
		})
	}, [slides])

	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 800,
		pauseOnFocus: true,
		autoplay: true,
		autoplaySpeed: 20000,
		slidesToShow: 1,
		slidesToScroll: 1,
		slidesPerRow: 1,
		rows: 1,
		accessibility: false,
		fade: false,
		focusOnSelect: false,
		adaptiveHeight: false,
		centerMode: false,
		variableWidth: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					slidesPerRow: 1,
					rows: 1,
				}
			},
			{
				breakpoint: 720,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					slidesPerRow: 1,
					rows: 1,
				}
			},
		]
	}





  return (
		<section class="news-module content border homepage-news c1 grid-12 is-inview img-3-span-9 inview">
			<div class="animated-border-top"></div>
			<div class="animated-border-right"></div>
			<div class="animated-border-bottom"></div>
			<div class="animated-border-left"></div>

			<Slider {...settings} className="span-12">
				{slidesPack ? slidesPack : null}
			</Slider>
		</section>
  )
}

export default NewsModule