import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import ReactHtmlParser from 'react-html-parser'

import Footer from '../components/Footer'

import Content from "../components/Content"
//import Video from "../components/Video/HTML"

import Modal from '../components/Modal'
import Newsletter from '../components/Form/CF7/Newsletter'
import logoSRC from '../components/Footer/logo_compact.png'

export default ({ data, pageContext }) => {
  const [newsletterModal, setNewsletterModal] = useState(false)
  
  const footerImage = data.wordpressAcfOptions.options.footer_image.localFile?.childImageSharp.fluid
  const footerCopy = data.wordpressAcfOptions.options.footer_copy

  useEffect(() => {
    if(pageContext.slug === '/') {
      // lol ye, cavemanish, but fuck I ain't got time
      setTimeout(() => {
        const lel = document.getElementById('evg-md-nws')
        lel.click()
      }, 4000);
    }
  },[pageContext.slug])

  return (
    <>
      <main className={`c0 main-${pageContext.slug === "/" ? "frontpage" : pageContext.slug}`}>

        {data.wordpressPage?.yoast_head ?
          <Helmet>{ ReactHtmlParser(data.wordpressPage?.yoast_head) }</Helmet>
        :
          <SEO title={data.wordpressPage?.name} description={data.wordpressPage?.description} />
        }

        { data.wordpressPage.acf.sections_page ?
          <Content { ...data } />
        : null }

        {/* <Video file="/rain.mp4" /> */}

        <a href="#evg-modal-signup" className="hidden" id="evg-md-nws">click me</a>
        <Modal trigger="#evg-modal-signup">
          <div className="signup-intro">
					  <img src={logoSRC} alt="Alternative Logo" />
            <h3 className="text-center uppercase" style={{marginBottom: '.5em'}}>Get special offers and the latest updates delivered straight to your inbox</h3>
          </div>
          <Newsletter />
        </Modal>
  
      </main>

      <Footer image={footerImage} copy={footerCopy} />
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    wordpressPage( id: { eq: $id } ) {
      id
      title
      slug
      date(formatString: "MM-DD-YYYY")
      author
      content
      yoast_head
      acf {
        sections_page {
          ... on WordPressAcf_hero {
            slides {
              img {
                localFile {
                  childImageSharp {
                    fluid (
                      maxWidth: 1920,
                      quality: 85,
                      srcSetBreakpoints: [960,1280,1920,2560]
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              wysiwyg
              video {
                localFile {
                  url
                }
              }
            }
            size
          }
          ... on WordPressAcf_content {
            img {
              localFile {
                childImageSharp {
                  fluid (
                    maxWidth: 1280,
                    quality: 70,
                    srcSetBreakpoints: [960,1280,1920,2560]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            content
            type
            color
            classes
          }
          ... on WordPressAcf_parallax {
            img {
              localFile {
                childImageSharp {
                  fluid (
                    maxWidth: 1280,
                    quality: 70,
                    srcSetBreakpoints: [960,1280,1920,2560]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            content
          }
          ... on WordPressAcf_cta {
            column {
              title
              button_link
              button_text
              description
              img {
                localFile {
                  childImageSharp {
                    fluid (
                      maxWidth: 1280,
                      quality: 70,
                      srcSetBreakpoints: [960,1280,1920,2560]
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_contact {
            type
            map {
              address
              lat
              lng
              zoom
            }
          }
          ... on WordPressAcf_were_ready {
            img {
              localFile {
                childImageSharp {
                  fluid (
                    maxWidth: 1280,
                    quality: 90,
                    srcSetBreakpoints: [960,1280,1920,2560]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on WordPressAcf_logistics {
            img {
              localFile {
                childImageSharp {
                  fluid (
                    maxWidth: 1280,
                    quality: 90,
                    srcSetBreakpoints: [960,1280,1920,2560]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on WordPressAcf_events {
            upcoming
            type
          }
          ... on WordPressAcf_testimonials {
            testimonials {
              quote
              by
              profession
            }
          }
          ... on WordPressAcf_gallery {
            class
            gallery {
              localFile {
                childImageSharp {
                  fixed (
                    height: 800
                  ) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on WordPressAcf_text_block {
            wysiwyg
          }
          ... on WordPressAcf_homepage_modules {
            id
          }
          ... on WordPressAcf_sponsorships {
            id
            img {
              localFile {
                childImageSharp {
                  fluid (
                    maxWidth: 1280,
                    quality: 70,
                    srcSetBreakpoints: [960,1280,1920,2560]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            logos {
              img {
                localFile {
                  childImageSharp {
                    fluid (
                      maxWidth: 1280,
                      quality: 70,
                      srcSetBreakpoints: [960,1280,1920,2560]
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              url
            }
          }
          ... on WordPressAcf_faq {
            id
            class
            faq {
              q
              a
            }
          }
          ... on WordPressAcf_what_we_do_module {
            id
          }
          ... on WordPressAcf_apply {
            id
            class
            apply {
              q
              a
            }
          }
          ... on WordPressAcf_activation {
            id
          }
          ... on WordPressAcf_our_locations {
            id
          }
          ... on WordPressAcf_image {
            img {
              localFile {
                childImageSharp {
                  fluid (
                    maxWidth: 1280,
                    quality: 90,
                    srcSetBreakpoints: [960,1280,1920,2560]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        footer_image {
          localFile {
            childImageSharp {
              fluid (
                maxWidth: 1280,
                quality: 90,
                srcSetBreakpoints: [720,1280,1920,2560]
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        footer_copy
      }
    }
    allWordpressWpLocation(filter: {}, sort: {fields: wordpress_id, order: ASC}) {
      edges {
        node {
          id
          wordpress_id
          slug
          location_type
          title
          acf {
            img {
              localFile {
                childImageSharp {
                  fluid (
                    maxWidth: 1280,
                    quality: 70,
                    srcSetBreakpoints: [960,1280,1920,2560]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            details
          }
        }
      }
    }
    allWordpressWpLocationType(filter: {}, sort: {fields: wordpress_id, order: ASC}) {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
  }
`