import React, { useState } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'
import Select from 'react-select'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'


function CF7Basic(props) {
	
	const { register, handleSubmit, errors, setValue, reset } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-name', form.name)
		data.append('your-email', form.email)
		data.append('your-phone', form.phone)
		data.append('your-scope', form.scope)
		data.append('your-message', form.message)

		console.log('this is form', form)
		
		const url = 'https://cms.deepsleepstudio.com/client/SWARM/wp-json/contact-form-7/v1/contact-forms/7/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}




	// Select
	const customStyles = {
		control: (base, state) => ({
			...base,
			boxShadow: "none",
		}),
		option: (base, state) => ({
			...base,
			color: state.isSelected ? '#000' : state.isFocused ? '#000' : '#fff',
		}),
		valueContainer: (base) => ({
			...base,
			minHeight: 30,
		})
	}

	return (
		<form
			name="contact"
			method="post"
			action="/thanks/"
			onSubmit={handleSubmit(onSubmit)}
		>

			<h3>CONTACT US</h3>

			<label>
				<input type="text" placeholder="Name" name="name" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.name && <p className="small margin-off">Your name is required.</p>}
			</label>
			<label>
				<input type="text" placeholder="E-mail" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
				{errors.email && <p className="small margin-off">A correct email is required.</p>}
			</label>
			<label>
				<input type="text" placeholder="Phone" name="phone" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.phone && <p className="small margin-off">Your phone is required.</p>}
			</label>
			<label htmlFor="scope" style={{color: '#fff'}}>
				<h5 className="fs-85" style={{marginLeft: '1em', marginBottom: '.5em', color: '#000'}}>What scope of work are you interested in?</h5>
			<Select
				name="scope"
				ref={register('scope')}
				className="select-scope"
				onChange={e => setValue('scope', e && e.value)}
				options={[
					{ value: 'Blank', label: 'Please Select:' },
					{ value: 'SWARMx Experiential Marketng', label: 'SWARMx Experiential Marketng' },
					{ value: 'Planning & Production', label: 'Planning & Production' },
					{ value: 'Venue Management & Scouting ', label: 'Venue Management & Scouting ' },
					{ value: 'Permitting & Expediting', label: 'Permitting & Expediting' },
					{ value: 'SWARMTKG Marketing & Advertising', label: 'SWARMTKG Marketing & Advertising' },
					{ value: 'Bar Operations & Food Concessions', label: 'Bar Operations & Food Concessions' }
				]}
				styles={customStyles}
				theme={theme => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						text: '#ffffff',
						primary: '#F0BE43',
						primary75: '#F0BE43',
						primary50: '#F0BE43',
						primary25: '#F0BE43',
					
						danger: '#DE350B',
						dangerLight: '#FFBDAD',
					
						neutral0: '#000000',
						neutral5: '#F0BE43',
						neutral10: 'hsl(0, 0%, 90%)',
						neutral20: '#F0BE43',
						neutral30: 'hsl(0, 0%, 30%)',
						neutral40: 'hsl(0, 0%, 60%)',
						neutral50: 'hsl(0, 0%, 92%)',
						neutral60: 'hsl(0, 0%, 40%)',
						neutral70: 'hsl(0, 0%, 70%)',
						neutral80: 'hsl(0, 0%, 90%)',
						neutral90: 'hsl(0, 0%, 100%)',
					},
				})}
			/>
			</label>
			<label className="fatter">
				<textarea placeholder="Message" name="message" ref={register} />
			</label>

			<button type="submit">SUBMIT {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
			
			<p className="message small">{message}</p>

		</form>
	)
}

export default CF7Basic