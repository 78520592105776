import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Img from 'gatsby-image'
import logoSRC from './logo_compact.png'

import './style.scss'

function HomepageModules(props) {


	const data = useStaticQuery(graphql`
	query {
		wordpressPage(id: {eq: "e5eb8154-44ae-5513-b0df-5d4da8619151"}) {
			childrenWordPressAcfDuoContent {
				wysiwyg
				wysiwyg2
			}
		}
	}
	`)

	const content = data?.wordpressPage?.childrenWordPressAcfDuoContent?.[0]?.wysiwyg
	const content2 = data?.wordpressPage?.childrenWordPressAcfDuoContent?.[0]?.wysiwyg2

  return (
		<section id="duo-content" className='duo-content c5 text-center border flex-12 gap-075 is-inview'>
			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>

			{/* <h6 style="text-align: center;">Founded in 2014 by leading event producers with over 30 years of experience, producing over 100 events annually, and attracting over 2 million people each year - SWARM has continued to push the boundaries of innovative and creative achievement as a premier event, marketing and production agency.</h6>
			<h6 style="text-align: center;">SWARM has worked with leading industries to deliver seamlessly executed and highly compelling events that range from high-profile conferences, brand launches, conventions, and sports sponsorship activations, to food and beverage festivals, live music concerts, and high-end receptions.</h6> */}

			<div className="wysiwyg-left span-6 span-mobile-12">
				<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content}} />
				<img class="logo-box" src={logoSRC} alt="Alternative Logo" />
			</div>
			<div className="wysiwyg-right span-6 span-mobile-12">
				<div className='content-holder animated' dangerouslySetInnerHTML={{__html: content2}} />
			</div>
		</section>
  )
}

export default HomepageModules