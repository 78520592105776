import React, { useState } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faUpload } from '@fortawesome/free-solid-svg-icons'

function Apply(props) {
	console.log(faUpload)
	const [uploadLabel, setUploadLabel] = useState('Upload Resume...')
	
	const { register, handleSubmit, errors, reset } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onUploadChange = (e) => {
		setUploadLabel('Upload: ' + e.currentTarget.files[0].name)
	}

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-name', form.name)
		data.append('your-email', form.email)
		data.append('your-phone', form.phone)
		data.append('your-position', form.position)
		data.append('your-resume', form.resume[0])
		data.append('your-message', form.message)

		setUploadLabel(form.resume[0].name)
		
		const url = 'https://cms.deepsleepstudio.com/client/SWARM/wp-json/contact-form-7/v1/contact-forms/163/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<form
			name="contact"
			method="post"
			action="/thanks/"
			onSubmit={handleSubmit(onSubmit)}
			className="cf7-apply"
		>

			<h3>APPLY HERE:</h3>

			<label>
				<input type="text" placeholder="Name" name="name" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.name && <p className="small margin-off">Your name is required.</p>}
			</label>
			<label>
				<input type="text" placeholder="E-mail" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
				{errors.email && <p className="small margin-off">A correct email is required.</p>}
			</label>
			<label>
				<input type="text" placeholder="Phone" name="phone" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.phone && <p className="small margin-off">Your phone is required.</p>}
			</label>
			<label>
				<input type="text" placeholder="Position" name="position" ref={register({required: true, message: "error message"})} />
				{errors.position && <p className="small margin-off">The position you are applying for is required.</p>}
			</label>
			<label className="inputfile">
				<a className="inputfilelabel btn"><span><FontAwesomeIcon icon={faUpload} />{uploadLabel}</span></a>
				<input type="file" placeholder="Resume" name="resume" ref={register({required: true, message: "error message"})} onChange={(e) => onUploadChange(e)} />
				{errors.resume && <p className="small margin-off">Your resume is required, or the filetype not allowed. Try PDF, DOC, DOCX, under 25Mb</p>}
			</label>
			<label className="fatter">
				<textarea placeholder="Message" name="message" ref={register} />
			</label>

			<button type="submit">SUBMIT {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
			
			<p className="message small">{message}</p>

		</form>
	)
}

export default Apply