import React, { useState, useEffect } from 'react'
import { Transition, SwitchTransition } from 'react-transition-group'
import anime from 'animejs'

import Location from './Location'

import './style.scss'

function OurLocations(props) {
	const [currentTabTab, setCurrentTabTab] = useState(3)
	const [currentTab, setCurrentTab] = useState(0)


	// Animations
	const baseDuration = 400
	// Animating fade in/out
	const fadeIn = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [0, 1],
				translateX: ['1em', 0],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)',
			})
	}
	const fadeOut = element => {
		anime
			.timeline()
			.add({
				targets: element,
				opacity: [1, 0],
				translateX: [0,'-1em'],
				duration: baseDuration,
				easing: 'cubicBezier(.5,.08,.54,.9)'
			})
	}

	// CPT Location
	const locations = props.allWordpressWpLocation.edges
	// Taxonomy
	const locationsTab = props.allWordpressWpLocationType.edges

	// GrandParent Tabs
	const TabTabTabPack = locationsTab.map((locationTab, i) => {

		return (
			<div className={`location-general padd-1 ${currentTabTab === locationTab.node.wordpress_id ? 'current' : null}`} onClick={() => onTabTabChange(locationTab.node.wordpress_id)} onKeyDown={() => onTabTabChange(locationTab.node.wordpress_id)} role="button" tabIndex={0}>
				<h6>{locationTab.node.name}</h6>
			</div>
		)
	})

	// Parent Tabs
	const TabTabPack = locations.map((location, i) => {
		if (location.node.location_type[0] === currentTabTab) {
			return (
				<div className={`location-specific padd-1 ${currentTab === i ? 'current' : null}`} onClick={() => onTabChange(i)} onKeyDown={() => onTabChange(i)} role="button" tabIndex={0}>
					<p dangerouslySetInnerHTML={{__html: location.node.title}} />
				</div>
			)
		}
		return false
	})

	// On Parent Tab Change
	function onTabChange(i) {
		setCurrentTab(i)
	}

	// On Grandparent Tab Change
	function onTabTabChange(i) {
		setCurrentTabTab(i)
	}

	// Update Tab Based on Grandparent Change
	useEffect(() => {
		const newTab = locations.findIndex((e) => e.node.location_type[0] === currentTabTab)
		setCurrentTab(newTab)
	},[currentTabTab,locations])


  return (
		<section id="our-locations" className='modules c3 border grid-12 text-center is-inview'>
			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>
			<div className="span-12 padd-2 c3">
				<h2>Venue Management &amp; Scouting</h2>
				<p>Having managed properties and venues in the hottest parts of South Florida including Wynwood, Miami Beach, Coconut Grove, Fort Lauderdale and more, SWARM has intimate knowledge of the best event spaces in the region. As big weeks take over South Florida like Art Basel and Miami Music Week, these venues get locked up. Count on us to secure your event's location.</p>
			</div>
			<div className="locations-general span-12 c3 flex center padd-1 padd-top-off hidden">
				{TabTabTabPack}
			</div>
			<div className="locations-specific span-12 c5 fs-85">
				<SwitchTransition>
					<Transition
						key={currentTabTab}
						timeout={baseDuration}
						appear={true}
						onEntering={fadeIn}
						onExiting={fadeOut}
					>
						<div className="span-12 flex center">
							{TabTabPack}
						</div>
					</Transition>
				</SwitchTransition>
			</div>
			<div className="span-12 c5 is-inview">
				<SwitchTransition>
					<Transition
						key={currentTab}
						timeout={baseDuration}
						appear={true}
						onEntering={fadeIn}
						onExiting={fadeOut}
					>
						<Location { ...locations[currentTab].node } />
					</Transition>
				</SwitchTransition>
			</div>
		</section>
  )
}

export default OurLocations