import React, {useEffect, useState, useContext} from 'react'
import './style.scss'

import { Link } from 'gatsby'
import Event from './EventBox'
import EventTile from './EventTile'

import APIContext from '../../../../context/APIContext'
import Newsletter from '../../../Form/CF7/Newsletter'

function Events(props) {
	const apiContext = useContext(APIContext)
	const [eventData, setEventData] = useState(false)

	// API
	const apiRequest = {
		id: null,
		path: 'event',
		refresh: 60000
	}
	useEffect(() => {
		// Set the API needed for current page
		apiContext.setAPI(apiRequest)
	},[])
	
	// Reorder Data
	function reOrderData(data) {
		let reorderedData = [...data]
		reorderedData.sort(function(a,b){
			return new Date(a.acf.event_start_daytime) - new Date(b.acf.event_start_daytime)
		})
		return reorderedData
	}

	useEffect(() => {
		if(apiContext.data) {
			const reorderedData = reOrderData(apiContext.data)
			const eventDataMap = reorderedData.map((node,i) => {
				if(props.type === "regular" && node.acf.type === "regular") {
					return (
						<Event key={node.id} { ...node } />
					)
				} else if (props.type === "proprietary" && node.acf.type === "proprietary") {
					return (
						<EventTile key={node.id} { ...node } />
					)
				}
				return false
			})
			setEventData(eventDataMap)
		}
	},[apiContext.data])
	


	
	if(props.type === "regular") {
		return (
			<section className={`events ${eventData && eventData[0] === false ? 'c5 inview' : 'c2'} border grid-12 gap-1 is-inview`}>

				<div className="animated-border-top"></div>
				<div className="animated-border-right"></div>
				<div className="animated-border-left"></div>

				{eventData && eventData[0] === false ?
					<div className="upcoming-events-newsletter c5 animated padd-2">
						<h3 className="text-center">SWARM with us.</h3>
						<h6 className="text-center">Sign up and stay up to date on upcoming events</h6>
						<Newsletter />
					</div>
				: eventData ?
					<div className="span-12 animated padd-2">
						<h2 className="text-center">Upcoming Events</h2>
					</div>
				:
					<div className="span-12 animated padd-2">
						<h2 className="text-center">Loading...</h2>
					</div>
				}

				<div className="events-grid grid-12 padd-bottom-2 gap-1 gap-1-row is-inview">
					<div className="animated-border-top"></div>
					<div className="animated-border-right"></div>
					<div className="animated-border-left"></div>
					{props.upcoming ?
						<>
							{eventData ? eventData :
								<>
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
								</>
							}
						</>
						: 
						<>
							{eventData ? eventData :
								<>
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
									<Event dummy={true} />
								</>
							}
						</>
					}
				</div>

				{props.upcoming ?
					<div className="span-12">
						<Link to={'/our-work/'} className="btn upcoming-events-btn"><span>View All Events</span></Link>
					</div>
				: null }
			</section>
		)
	} else if(props.type === "proprietary") {
		return (
			<section className="events c5 border border-top-off grid-12 gap-1 is-inview">
				<div className="animated-border-top"></div>
				<div className="animated-border-right"></div>
				<div className="animated-border-left"></div>
				<div className="events-grid proprietary grid-12 gap-075 gap-075-row">
					{eventData ? eventData :
						<>
							<EventTile dummy={true} />
							<EventTile dummy={true} />
							<EventTile dummy={true} />
							<EventTile dummy={true} />
							<EventTile dummy={true} />
							<EventTile dummy={true} />
							<EventTile dummy={true} />
							<EventTile dummy={true} />
							<EventTile dummy={true} />
							<EventTile dummy={true} />
							<EventTile dummy={true} />
							<EventTile dummy={true} />
						</>
					}
				</div>
			</section>
		)
	}
}

export default Events