import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'
import Video from '../../../Video/HTML'

import './style.scss'

function ModulesWhatWeDo(props) {

	const data = useStaticQuery(graphql`
	query {
		iconEye: file(relativePath: { eq: "icon-eye.png" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
	`)

  return (
		<section id="homepage-modules" className='modules c5 text-center border grid-12 gap-075 is-inview'>
			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>
			<div className="span-4 span-12-mobile padd-2 hover-reveal">
				<div className="hover-reveal-front">
					<h2>See what we do</h2>
					<Img fluid={data.iconEye.childImageSharp.fluid}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg icon icon-bulb"
					/>
				</div>
				<div className="hover-reveal-back text-left fs-85">
					<div className="text-box">
						<ul>
							<li>Defining the target market</li>
							<li>Design event to achieve desired goals</li>
							<li>Understanding the market and what event type will work</li>
							<li>Choosing the right venue</li>
							<li>Selecting the perfect dates</li>
						</ul>
					</div>
					<Img fluid={data.iconEye.childImageSharp.fluid}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg icon icon-bulb"
					/>
				</div>
			</div>
			<div className="span-8 span-12-tablet video-section">
				<div className="video-wrap">
					<Video file={`../rain.mp4`} />
				</div>
			</div>
		</section>
  )
}

export default ModulesWhatWeDo