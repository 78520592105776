import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Img from 'gatsby-image'

import './style.scss'

function HomepageModules(props) {

	const data = useStaticQuery(graphql`
	query {
		iconBulb: file(relativePath: { eq: "icon-bulb.png" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		bgBulb: file(relativePath: { eq: "bg-bulb.jpg" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		iconSpanner: file(relativePath: { eq: "icon-spanner.png" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		bgSpanner: file(relativePath: { eq: "bg-spanner.jpg" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		iconCrown: file(relativePath: { eq: "icon-crown.png" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		bgCrown: file(relativePath: { eq: "bg-crown.jpg" }) {
			childImageSharp {
				fluid (
					maxWidth: 1280,
					quality: 70,
					srcSetBreakpoints: [960,1280,1920,2560]
				) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
	`)

  return (
		<section id="homepage-modules" className='modules c5 text-center border grid-12 gap-075 is-inview'>
			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>
			<Link to={'/our-work/'} className="span-4 span-12-mobile padd-2 hover-reveal">
					<Img fluid={data.bgBulb.childImageSharp.fluid}
						imgStyle={{objectFit: 'cover'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg cover"
					/>
					<Img fluid={data.iconBulb.childImageSharp.fluid}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg icon icon-bulb"
					/>
				<div className="hover-reveal-front">
					<h2>Our Work</h2>
					{/* <h3 className="sub">Define, Design &amp; Develop</h3> */}
					<h3 className="ver c5">1.0</h3>
					{/* <Img fluid={data.iconBulb.childImageSharp.fluid}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg icon icon-bulb"
					/> */}
				</div>
				<div className="hover-reveal-back text-left fs-85">
					<div className="text-box">
						<h4>Events from the groundup - We begin by conceptualizing and strategizing to acheive our clients' goals.</h4>
					</div>
				</div>
			</Link>
			<div className="span-4 span-12-mobile padd-2 hover-reveal">
					<Img fluid={data.bgSpanner.childImageSharp.fluid}
						imgStyle={{objectFit: 'cover'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg cover"
					/>
					<Img fluid={data.iconSpanner.childImageSharp.fluid}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg icon icon-spanner"
					/>
				<div className="hover-reveal-front">
					<h2>Our Path</h2>
					{/* <h3 className="sub">Build out &amp; Execution</h3> */}
					<h3 className="ver c5">2.0</h3>
					{/* <Img fluid={data.iconSpanner.childImageSharp.fluid}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg icon icon-spanner"
					/> */}
				</div>
				<div className="hover-reveal-back text-left fs-85">
					<div className="text-box">
						<h4>As a full-service agency, we don't just think of the plan, we execute the plan.</h4>
					</div>
				</div>
			</div>
			<div className="span-4 span-12-mobile padd-2 hover-reveal">
					<Img fluid={data.bgCrown.childImageSharp.fluid}
						imgStyle={{objectFit: 'cover'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg cover"
					/>
					<Img fluid={data.iconCrown.childImageSharp.fluid}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg icon icon-crown"
					/>
				<div className="hover-reveal-front">
					<h2>Our Partners</h2>
					{/* <h3 className="sub">Connect brands to people</h3> */}
					<h3 className="ver c5">3.0</h3>
					{/* <Img fluid={data.iconCrown.childImageSharp.fluid}
						imgStyle={{objectFit: 'contain'}}
						objectPosition='50% 50%'
						loading='auto'
						fadeIn={true}
						className="bg icon icon-crown"
					/> */}
				</div>
				<div className="hover-reveal-back text-left fs-85">
					<div className="text-box">
						<h4>We engage with consumers in a way that resonates with your target market and is defined by tangible reporting.</h4>
					</div>
				</div>
			</div>
		</section>
  )
}

export default HomepageModules