import React from 'react'
import './style.scss'

import { Link } from 'gatsby'

function Event(props) {
	//const d = new Date()

	if(props.dummy === true) {
		return (
			<article className="event proprietary span-3 span-6-tablet span-12-mobile c0 is-inview">
				<div className="image c4"></div>
			</article>
		)
	}

  return (
		<article className="event loaded proprietary span-3 span-6-tablet span-12-mobile c0 is-inview">
			<Link to={`/event?id=${props.id}`} className="image c4" state={{ singleEvent: {...props}}}>
				{props.acf.img ?
					<img
						className="img-cover"
						src={props.acf.img.sizes.medium} 
						alt=""  
						srcSet={`${props.acf.img.sizes.large} 1024w,
								${props.acf.img.sizes.medium_large} 768w,
								${props.acf.img.sizes.medium} 300w,
								${props.acf.img.sizes.thumbnail} 150w`}
						sizes="(min-width: 800px) 25vw, 100vw"
					/>
				: null }
				{props.acf.logo ?
					<div className="logo">
						<img
							className="img-contain"
							src={props.acf.img.sizes.medium} 
							alt=""  
							srcSet={`${props.acf.logo.sizes.large} 1024w,
									${props.acf.logo.sizes.medium_large} 768w,
									${props.acf.logo.sizes.medium} 300w,
									${props.acf.logo.sizes.thumbnail} 150w`}
							sizes="(min-width: 800px) 25vw, 100vw"
						/>
					</div>
				: null }
				<div className="bg-overlay"></div>
			</Link>
		</article>
  )
}

export default Event