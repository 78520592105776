import React from 'react'
import Img from 'gatsby-image'
import './style.scss'
import Carousel from './Carousel'

function Sponsorships(props) {
	const image = props.img.localFile?.childImageSharp.fluid

	return (
		<section className={`border sponsorships grid-12 content-box is-inview ${props.class}`}>

			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>

			<div className="span-7 span-12-tablet sponsorships-l">
				<div className="content-box padd-2">
					<h2>Sponsorships</h2>
					<h3>Want to partner with SWARM?<br/>You are in good company...</h3>
					<p>You bring the brand, swarm will provide an annual audience of over 2.5 million engaged guests ready for a special experience. With partnership opportunities ranging from presenting rights to beverage exclusivity and digital marketing, we will work with you on developing a package to activate your brand in a way that resonates with consumers.</p>
				</div>
				<div className="brand-image is-inview c5">
					<Carousel {...props} />
				</div>
			</div>
			<div className="span-5 span-12-tablet sponsorships-r padd-2 padd-top-4 padd-bottom-4 border-left">
				<h3>We design &amp; conceptualize</h3>
				<p>From beginning to end, we can help you create the ultimate and unique vision for your desired event layout. We activate your space with the creative intention to cater to your preferred audience.</p>
				<h3>We build out</h3>
				<p>With a team of highly skilled production staff, we can provide full event production and build-out with all of the proper permitting, logistics, audio, video, and event lighting execution to secure the credibility of your event.</p>
				<h3>We operate</h3>
				<p>From operational planning to delivery management, event logistics and operations are our top priority as we seek to use the very best professionals trained in providing the thought-out services for a vast variety of circumstances within an event.</p>
			</div>
		</section>
  )
}

export default Sponsorships