import React, {useState, useEffect} from 'react'

//import PropTypes from 'prop-types'
import './style.scss'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img from 'gatsby-image'


function Carousel(props) {
	const [slidesPack, setSlidesPack] = useState()
	
	const slides = props.logos
	const settings = {
		dots: false,
		arrows: true,
		infinite: true,
		speed: 800,
		pauseOnFocus: true,
		autoplay: true,
		autoplaySpeed: 8000,
		slidesToShow: 3,
		slidesToScroll: 3,
		slidesPerRow: 1,
		rows: 2,
		accessibility: false,
		fade: false,
		focusOnSelect: false,
		adaptiveHeight: false,
		centerMode: false,
		variableWidth: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					slidesPerRow: 1,
					rows: 2,
				}
			},
			{
				breakpoint: 720,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					slidesPerRow: 1,
					rows: 2,
				}
			},
		]
	}

	useEffect(() => {

		setSlidesPack(() => {

			// Map Slides to gallery stripe
			const slidesMap = slides.map((slide, i) => {
				const image = slide.img.localFile?.childImageSharp.fluid
				return (
					<div key={`slide-${i}`} className="slide c5">
						{ slide ?
							<Img fluid={image}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
								fadeIn={true}
								loading='auto'
								className="bg"
							/>
						: null }
					</div>
				)
			})
			return slidesMap
		})
	}, [slides])

	return (
		<Slider {...settings}>
			{slidesPack ? slidesPack : null}
		</Slider>
  )
}

export default Carousel