import React from 'react'

import QA from './QA'

import './style.scss'

function Faq(props) {

	const faqList = props.faq.map((node, i) => {
		if(i % 2 === 0) {
			return (
				<QA { ...node } />
			)
		}
		return false
	})
	const faqListOdd = props.faq.map((node, i) => {
		if(i % 2 !== 0) {
			return (
				<QA { ...node } />
			)
		}
		return false
	})

  return (
		<section className={`faq grid-12 c0 border is-inview ${props.class}`}>
			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>
			<div className="span-12 span-12-mobile padd-2">
				<h2>FAQ:</h2>
			</div>
			<div className="span-6 span-12-mobile">
				{faqList}
			</div>
			<div className="span-6 span-12-mobile">
				{faqListOdd}
			</div>
		</section>
  )
}

export default Faq