import React from 'react'
import ApplyForm from '../../../Form/CF7/Apply'
import './style.scss'
import QA from '../FAQ/QA'

function ApplySection(props) {

	const applyList = props.apply.map((node, i) => {
		return (
			<QA { ...node } />
		)
	})

	return (
		<section className={`border grid-12 content-box is-inview ${props.class}`}>
			<div className="animated-border-top"></div>
			<div className="animated-border-right"></div>
			<div className="animated-border-left"></div>

			<div className="span-6 span-12-mobile padd-2 apply-qa">
				<h3>Ready to roll with us?</h3>
				<p>At SWARM we hire people, not positions.
				We believe in a culture of inclusion and are seeking those
				with a strong work ethic and a drive to succeed.</p>
				<h2>Positions available:</h2>
				{applyList}
			</div>
			<div className="span-6 span-12-mobile border-left border-bottom contact contact-apply flex">
				<ApplyForm />
			</div>
		</section>
  )
}

export default ApplySection